<template>
  <div class="detail-index-bg">
    <div class="detail-index" v-loading="pageLoading">
        <Crumbs/>
      <div class="detail-index-top">
        <div class="detail-index-top-content-headPic">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/border-top.png"
            alt=""
          />
        </div>
        <div class="detail-index-top-content-footerPic">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/border-bottom.png"
            alt=""
          />
        </div>
        <div class="detail-index-top-left">
          <img style="width: 100%; height: 100%" :src="info.space_pic" alt="" />
        </div>
        <div class="detail-index-top-right">
          <div class="right-top">
            <div class="map-detail-top-title">
              <!-- {{ info.space_name }} -->
              <span class="name">
                {{ info.space_name }}
              </span>
              <span class="column"> | </span>
              <span class="area"> {{ info.measure_area }}m² </span>
            </div>
            <div class="maplist-detail-top-des">
              <ul class="maplist-detail-top-des-ul">
                <li class="maplist-detail-top-des-ul-li">
                  <img
                    style="
                      width: 0.24rem;
                      height: 0.24rem;
                      margin-right: 0.1rem;
                    "
                    src="https://image.bookgo.com.cn/%20webculture/jm/activity-detail-menbers.png"
                    alt=""
                  />
                  <div class="maplist-detail-top-des-ul-li-div">
                    <span>容纳人数：</span>{{ info.capacity }}人
                  </div>
                </li>
                <li class="maplist-detail-top-des-ul-li">
                  <img
                    style="
                      width: 0.24rem;
                      height: 0.24rem;
                      margin-right: 0.1rem;
                    "
                    src="https://image.bookgo.com.cn/%20webculture/jm/venue-jm-support.png"
                    alt=""
                  />
                  <div class="maplist-detail-top-des-ul-li-div">
                    <span>配套设施：</span>{{ info.supporting }}
                  </div>
                </li>
                <div class="line-div">
                  <div class="line"></div>
                </div>
                <li class="maplist-detail-top-des-ul-li">
                  <img
                    style="
                      width: 0.24rem;
                      height: 0.24rem;
                      margin-right: 0.1rem;
                    "
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_place_maplist.png"
                    alt=""
                  />
                  <div class="maplist-detail-top-des-ul-li-div">
                    <span>所属场馆：</span>{{ info.name }}
                  </div>
                </li>
                <li class="maplist-detail-top-des-ul-li">
                  <img
                    style="
                      width: 0.24rem;
                      height: 0.24rem;
                      margin-right: 0.1rem;
                    "
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_top_phone_icon.png"
                    alt=""
                  />
                  <div class="maplist-detail-top-des-ul-li-div">
                    <span>电话：</span>{{ info.phone }}
                  </div>
                </li>
                <li class="maplist-detail-top-des-ul-li">
                  <img
                    style="
                      width: 0.24rem;
                      height: 0.24rem;
                      margin-right: 0.1rem;
                    "
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_top_address_icon.png"
                    alt=""
                  />
                  <div class="maplist-detail-top-des-ul-li-div">
                    <span>地址：</span>{{ info.address_des }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="maplist-detail-top-button"
            v-if="info.is_open == 1"
            @click="handleServe"
          >
            立即预定
          </div>
        </div>
      </div>
      <div class="detail-index-bottom">
        <div class="detail-index-bottom-content">
          <div class="detail-index-top-content-footerPic">
            <img
              style="width: 100%; height: 100%"
              src="https://image.bookgo.com.cn/webculture/jm/border-bottom.png"
              alt=""
            />
          </div>
          <div class="detail-index-bottom-content-top">
            <div class="detail-index-bottom-content-top-title">场馆详情</div>
          </div>

          <div class="detail-index-bottom-content-bottom">
            <div class="detail-index-bottom-content-bottom-box">
              <div class="detail-index-bottom-content-bottom-box-top">
                <div class="detail-index-bottom-content-bottom-box-top-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_title_left_icon.png"
                    alt=""
                  />
                </div>
                <div class="detail-index-bottom-content-bottom-box-top-title">
                  简介
                </div>
              </div>
              <div class="detail-index-bottom-content-bottom-box-bottom">
                <div v-html="info.instruction">{{ info.instruction }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mydialog
      v-if="formDialogShow"
      :keyword="'扫码预定场馆'"
      :isShow="formDialogShow"
      :src="detailSrc"
      @close="formDialogShow = false"
    />
  </div>
</template>

<script>
import { getSpaceInfo } from "../../api/map/map.js";
import { genQRCode } from "@/api/common";
import mydialog from "@/components/myDialog";
import Crumbs from "@/views/components/crumbs";
export default {
  components: { mydialog,Crumbs },
  data() {
    return {
      pageLoading: true,
      shareCount: 0,
      upCount: 0,
      likeCount: 0,
      id: null,
      info: {},
      formDialogShow: false,
      detailType: "map_detail",
      map_team_list: [],
      detailSrc: "",
      tid: "",
    };
  },
  created() {},
  mounted() {
    document.documentElement.scrollTop = 0;
    this.tid = this.$route.query.tid;
    this.getDetail();
  },
  watch: {
    $route(newV) {
      document.documentElement.scrollTop = 0;
      this.tid = newV.query.tid;
      this.getDetail()
    },
  },
  methods: {
    getDetail() {
      let self = this;
      getSpaceInfo({ id: this.tid, aid: process.env.VUE_APP_AID }).then(
        (res) => {
          if (res.code == 0) {
            const regex = new RegExp("<img", "gi");
            res.data.instruction = res.data.instruction.replace(
              regex,
              `<img style="max-width: 100%; height: auto;margin:0 auto"`
            );
            this.info = res.data;
          }
          self.pageLoading = false;
        }
      );
    },
    handleServe() {
      let params = {
        wxapp_name: "culture",
        page:
          "bookgo/culture/venue/venueSpace/detail/detail?id=" +
          this.tid +
          "&aid=" +
          process.env.VUE_APP_AID,
        scene: "",
        aid: process.env.VUE_APP_AID,
      };
      this.formDialogShow = true;
      genQRCode(params).then((res) => {
        let pic = res.data;
        this.detailSrc = pic;
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/detail.scss";
@import "../../assets/scss/venue_default_detail.scss";
</style>
